<template>
    <div class="colMax">
        <div class="ColBox">
            <!-- 分类-->
            <div class="Law-Tab">
                <div class="Law-Tab-Nav">
                    <div v-for="(item, index) in NavList" :key="index" :class="{ btna: count == index }" @click="change(index)" class="li">
                        <div style="width:100%;height: 100%;display: flex;align-items: center;justify-content: center;">
                            <div :class="{ actived: active == index }" class="Zt">{{ item }}</div>
                        </div>
                        <div class="colBorder" :class="{ abc: abd == index }"></div>
                    </div>
                </div>
            </div>
            <!-- 分类内容 -->
            <div class="end-cont" :class="{ dis: btnnum == 0 }">
                <div class="ColBoxOne">
                    <div style="width:40%;display: flex;flex-direction: column;align-items: center;">
                        <!-- 头像  -->
                        <div class="ColHead"> <img src="../../../assets/PersonalCenter/HeadPortrait.png" alt="" style="width:100%;height:100%;"> </div>
                        <div style="font-size:2.5vh;font-weight:300; margin-top: 3vh;">拨打热线，您的客户在线为你服务~~</div>
                        <div style="font-size:2.7vh;margin-top:3vh;">0731-2556-7890</div>
                    </div>
                </div>
            </div>
            <div class="end-cont" :class="{ dis: btnnum == 1 }">
                <!-- 地址管理 -->
                <div class="ColBoxTwo">
                    <!-- 标题 -->
                    <div class="ColTitle">
                        <div style="width:15%;display: flex;justify-content: space-between;">
                            <!-- 收获地址 -->
                            <div class="ColSite">收货地址</div>
                            <!-- 新增收货地址 -->
                            <div class="ColNewly" @click="apply()">新增收货地址</div>
                        </div>
                        <!-- 按钮 -->
                        <div class="ColButton" @click="returned()">返回上一级</div>
                    </div>
                    <!-- 表格 -->
                    <div class="ColTable">
                        <!-- 标题 -->
                        <div class="ColTableOne">
                            <div class="ColTitleTwo">收货人</div>
                            <div style="width:20%;display: flex;justify-content: center;align-items:center;">
                                所在地区</div>
                            <div style="width:25%;display: flex;justify-content: center;align-items:center;">
                                详细地址</div>
                            <div class="ColTitleTwo">手机号码</div>
                            <div class="ColTitleTwo">操作</div>
                            <div class="ColTitleTwo"></div>
                        </div>
                        <!-- 内容 -->
                        <div style="width:100%;height:88%;overflow:hidden scroll;">
                            <div class="ColContent" v-for="(item, index) in AddressList" :key='index'>
                                <div class="ColContentOne">{{ item.deliName }}</div>
                                <div style="width:20%;display: flex;justify-content: center;align-items:center;">
                                    {{ item.provinceName }}{{ item.cityName }}{{ item.districtName }}</div>
                                <div style="width:25%;display: flex;justify-content: center;align-items:center;">
                                    {{ item.address }}</div>
                                <div class="ColContentOne">{{ item.tel }}</div>
                                <div class="ColContentOne"> <span @click="edit_address(item.addrId)" style="margin-right:2vh;cursor: pointer;">修改</span> | <span @click="del(item.addrId)" style="margin-left:2vh;cursor: pointer;">删除</span> </div>
                                <div class="ColContentOne">
                                    <div class="ColDefault" v-if="item.defaultTag">默认地址</div>
                                    <div class="ColDefaultone" v-else @click="setDefault(item.addrId,index)">设为默认地址</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="ColMaskOne" @click="masksCloseFun" v-if="videoStateOne == true"></div>
        <div class="ColMyPop" v-if="videoStateOne">
            <div class="ColFlex">
                <!-- 标题 -->
                <div class="ColTitleFive">新增地址</div>
                <!-- 输入框 -->
                <div style="width:100%;height:80%;">
                    <el-row class="OrdSite" inline>
                        <el-form class="OrdSiteOne" inline :rules="rules" :model="ruleForm" ref="ruleForm">
                            <div class="OrRedClass">
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:10.9%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    收货地址：</div>
                                <!-- 省 -->
                                <el-form-item prop="province" class="OrItem">
                                    <el-select v-model="ruleForm.province" placeholder="请选择" style="width:12vw;">
                                        <el-option v-for="(item, index) in province" :key="index" :label="item.areaName" :value="item.areaId" @click="selectProvince(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 市 -->
                                <el-form-item prop="city">
                                    <el-select v-model="ruleForm.cityValue" placeholder="请选择" @change="selectcity" style="width:12vw">
                                        <el-option v-for="(item, index) in cityarr" :key="index" :label="item.areaName" :value="item.areaId" @click="selectCountry(item.areaId)"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 县 -->
                                <el-form-item prop="country">
                                    <el-select v-model="ruleForm.RegionValue" placeholder="请选择" style="width:12vw">
                                        <el-option v-for="(item, index) of regionarr" :key="index" :label="item.areaName" :value="item.areaId" @click="district(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 详细地址 -->
                        <el-form class="OrdSiteOne" inline :rules="rules" :model="ruleForm" ref="ruleForm" style="margin-top:3vh;">
                            <div class="OrRedClass">
                                <!-- 详细地址 -->
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:10.8%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    详细地址:</div>
                                <el-form-item prop="DetailedAddress">
                                    <el-input v-model="ruleForm.DetailedAddress" placeholder="请输入详细地址信息,如街道、小区、楼栋号、单元等信息" style="width:44vw;" clearable></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 名称 -->
                        <el-form class="OrdSiteOne" inline :rules="rules" :model="ruleForm" ref="ruleForm" style="margin-top:3vh;">
                            <div class="OrRedClass">
                                <!-- 名称 -->
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:10.8%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    收货人：</div>
                                <el-form-item prop="name">
                                    <el-input v-model="ruleForm.name" placeholder="请填写汉字称呼" style="width:44vw;" clearable></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 手机号 -->
                        <el-form class="OrdSiteOne" inline :rules="rules" :model="ruleForm" ref="ruleForm" style="margin-top:3vh;">
                            <div class="OrRedClass">
                                <!-- 手机号 -->
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:11%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    手机号码：</div>
                                <el-form-item>
                                    <el-input placeholder="中国 + 86" style="width:10vw"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item prop="tel" style="margin-left:6.5vw;">
                                <el-input v-model="ruleForm.tel" placeholder="请填写11位手机号码" clearable>
                                    oninput="if(value.length > 11)value = value.slice(0, 11)"
                                    style="width:44.5vw;margin-top:2vh;"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-row>
                    <!-- 按钮 -->
                    <div class="ColFlexOne">
                        <div class="ColButtonOne">
                            <div @click="cancel()" style="width:45%;height:100%;background-color: rgba(221, 221, 221, 1);border-radius:0.5vh;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                                取消</div>
                            <div @click="affirm()" style="width:45%;height:100%;background-color: rgba(100, 162, 255, 1);border-radius:0.5vh;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                                确认</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 地址详情 -->
        <div class="ColMyPop" v-if="videoStateTwo">
            <div class="ColFlex">
                <!-- 标题 -->
                <div class="ColTitleFive">修改地址</div>
                <!-- 输入框 -->
                <div style="width:100%;height:80%;">
                    <el-row class="OrdSite" inline>
                        <el-form class="OrdSiteOne" inline :rules="RulesOne" :model="ruleFormOne" ref="ruleFormOne">
                            <div class="OrRedClass">
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:10.9%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    收货地址：</div>
                                <!-- 省 -->
                                <el-form-item prop="ProvinceOne" class="OrItem">
                                    <el-select v-model="ruleFormOne.ProvinceOne" placeholder="请选择" style="width:12vw;">
                                        <el-option v-for="(item, index) in province" :key="index" :label="item.areaName" :value="item.areaId" @click="selectProvince(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 市 -->
                                <el-form-item prop="CityValueOne">
                                    <el-select v-model="ruleFormOne.CityValueOne" placeholder="请选择" @change="selectcity" style="width:12vw">
                                        <el-option v-for="(item, index) in cityarr" :key="index" :label="item.areaName" :value="item.areaId" @click="selectCountry(item.areaId)"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- 县 -->
                                <el-form-item prop="RegionValueOne">
                                    <el-select v-model="ruleFormOne.RegionValueOne" placeholder="请选择" style="width:12vw">
                                        <el-option v-for="(item, index) of regionarr" :key="index" :label="item.areaName" :value="item.areaId" @click="district(item.areaId)">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 详细地址 -->
                        <el-form class="OrdSiteOne" inline :rules="RulesOne" :model="ruleFormOne" ref="ruleFormOne" style="margin-top:3vh;">
                            <div class="OrRedClass">
                                <!-- 详细地址 -->
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:10.8%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    详细地址:</div>
                                <el-form-item prop="DetailedAddressOne">
                                    <el-input v-model="ruleFormOne.DetailedAddressOne" placeholder="请输入详细地址信息,如街道、小区、楼栋号、单元等信息" style="width:44vw;" clearable></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 名称 -->
                        <el-form class="OrdSiteOne" inline :rules="RulesOne" :model="ruleFormOne" ref="ruleFormOne" style="margin-top:3vh;">
                            <div class="OrRedClass">
                                <!-- 名称 -->
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:10.8%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    收货人：</div>
                                <el-form-item prop="NameOne">
                                    <el-input v-model="ruleFormOne.NameOne" placeholder="请填写汉字称呼" style="width:44vw;" clearable></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                        <!-- 手机号 -->
                        <el-form class="OrdSiteOne" inline :rules="RulesOne" :model="ruleFormOne" ref="ruleFormOne" style="margin-top:3vh;">
                            <div class="OrRedClass">
                                <!-- 手机号 -->
                                <el-form-item class="OrdDot"></el-form-item>
                                <div style="width:11%; margin-left:-0.5vw; font-size:2vh;color: rgba(51, 51, 51, 1);">
                                    手机号码：</div>
                                <el-form-item>
                                    <el-input placeholder="中国 + 86" style="width:10vw"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item prop="TelOne" style="margin-left:6.5vw;">
                                <el-input v-model="ruleFormOne.TelOne" placeholder="请填写11位手机号码" clearable oninput="if(value.length > 11)value = value.slice(0, 11)" style="width:44.5vw;margin-top:2vh;"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-row>
                    <!-- 按钮 -->
                    <div class="ColFlexOne">
                        <div class="ColButtonOne">
                            <div @click="CancelOne()" style="width:45%;height:100%;background-color: rgba(221, 221, 221, 1);border-radius:0.5vh;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                                取消</div>
                            <div @click="DateOne()" style="width:45%;height:100%;background-color: rgba(100, 162, 255, 1);border-radius:0.5vh;display: flex;justify-content: center;align-items: center;cursor: pointer;">
                                确认</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import { province } from './map'
export default {
    data () {
        return {
            detailed: {},
            addressEdit: [],
            // 测试
            defaultTag: '',
            // 省的id
            ShengId: {},
            // 市的id
            ShiId: {},
            // 区的id
            DistrictOne: {},
            //地址列表
            AddressList: [],
            // 地址详情
            edit: {},
            // 选择省份
            province: [],
            cityarr: [],
            regionarr: [],
            ruleForm: {
                // 省份
                province: '',
                // 市
                cityValue: '',
                // 县
                RegionValue: '',
                // 详细地址
                DetailedAddress: '',
                // 昵称
                name: '',
                // 手机号
                tel: '',
            },
            ruleFormOne: {
                // 省份
                ProvinceOne: '',
                // 市
                CityValueOne: '',
                // 县
                RegionValueOne: '',
                // 详细地址
                DetailedAddressOne: '',
                // 昵称
                NameOne: '',
                // 手机号
                TelOne: '',
            },
            // 新增地址栏
            videoStateTwo: false,
            videoStateOne: false,
            checkedIndex: 0, // 默认选中的索引
            active: 0,
            NavList: ["客服", "地址管理",],
            count: 0,
            btnnum: 0,
            abd: 0,
            //新增地址校验   
            rules: {
                DetailedAddress: [
                    { required: true, message: "详情地址不能为空", trigger: "blur" },
                ],
                name: [
                    { required: true, message: "名称不能为空", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[\u4E00-\u9FA5]+$/.test(value) == false) {
                                callback(new Error("请输入中文名称"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    },
                ],
                tel: [
                    { required: true, message: "手机号不能为空", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (/^1[3456789]\d{9}$/.test(value) == false) {
                                callback(new Error("手机号格式不正确"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    },
                ]
            },
            detailedOne: {},
            // 修改地址id
            AddrIdOne: '',
            form: {
                img: '',
                title: '',
                jiage: '',
                shuliang: '',
                soldNum: '',
            },
            //修改地址校验   
            RulesOne: {
                DetailedAddressOne: [
                    { required: true, message: "详情地址不能为空", trigger: "blur" },

                ],
                NameOne: [
                    { required: true, message: "名称不能为空", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (/^[\u4E00-\u9FA5]+$/.test(value) == false) {
                                callback(new Error("请输入中文名称"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    },
                ],
                TelOne: [
                    { required: true, message: "手机号不能为空", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (/^1[3456789]\d{9}$/.test(value) == false) {
                                callback(new Error("手机号格式不正确"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    },
                ]

            },
            DetailedTwo: {},
            details: {},
            ShuJu: {
                img: '',
                title: '',
                jiage: '',
                soldNum: '',
                shuliang: '',
                id: '',
            }
        }

    },
    created () {
        // 地址列表
        this.SiteList()
        // 查省
        this.sheng()

    },

    mounted () {
        this.receive()
    },

    methods: {
        //接收路由传值
        receive () {
            this.details = this.$route.query;
            if (this.details.deviceId == 1) {
                this.change()
                this.active = 1;
                this.count = 1;
                this.btnnum = 1;
                this.abd = 1
            }
        },

        /*切换需要高亮的按钮*/
        change (index) {
            this.active = index;
            this.count = index;
            this.btnnum = index;
            this.abd = index
        },
        // 查省  
        sheng () {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/getProvinceList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.province = res.data.data

                    }
                })
                .catch(function (error) {
                });

        },
        // 查市
        selectProvince (id) {
            // 省的id 
            this.ShengId = id;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/getCityList/' + id,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    parentId: id,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.cityarr = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 查城市
        selectCountry (id) {
            // 市的id 
            this.ShiId = id;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/getDistrictList/' + id,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    parentId: id,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.regionarr = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 查区
        district (id) {
            this.DistrictOne = id
        },
        //地址列表请求数据
        SiteList () {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/customerDeliAddr/list',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },

            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.AddressList = res.data.data

                    }
                })
                .catch(function (error) {
                });
        },

        //点击返回购买页面
        returned () {
            this.ShuJu.img = this.details.img
            this.ShuJu.title = this.details.title
            this.ShuJu.jiage = this.details.jiage
            this.ShuJu.soldNum = this.details.soldNum
            this.ShuJu.shuliang = this.details.shuliang
            this.ShuJu.id = this.details.id
            this.$router.push({
                path: '/order',
                query: this.ShuJu

            });
        },
        // 点击增加地址
        apply () {
            this.videoStateOne = true;
        },
        // 点击查看详情
        edit_address (addrId) {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/customerDeliAddr/info/' + addrId,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.edit = res.data.data
                        this.ruleFormOne.ProvinceOne = res.data.data.provinceName
                        this.ruleFormOne.CityValueOne = res.data.data.cityName
                        this.ruleFormOne.RegionValueOne = res.data.data.districtName
                        this.ruleFormOne.DetailedAddressOne = res.data.data.address
                        this.ruleFormOne.NameOne = res.data.data.deliName
                        this.ruleFormOne.TelOne = res.data.data.tel
                        this.AddrIdOne = res.data.data.addrId
                    }
                })
                .catch(function (error) {
                });
            this.videoStateTwo = true;
        },
        // 点击修改地址
        DateOne () {
            var axios = require('axios');
            var config = {
                method: 'PUT',
                url: this.$Schttp + '/vtp/app/customerDeliAddr/update',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    deliName: this.ruleFormOne.NameOne,
                    address: this.ruleFormOne.DetailedAddressOne,
                    tel: this.ruleFormOne.TelOne,
                    provinceId: this.ShengId,
                    cityId: this.ShiId,
                    districtId: this.DistrictOne,
                    addrId: this.AddrIdOne,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.videoStateTwo = false;
                        this.SiteList()
                    } else {
                        this.$message({
                            message: '修改失败,请再选择一次省份',
                            type: 'success'
                        });
                    }
                })
                .catch(function (error) {
                });

        },
        // 点击取消修改地址1
        CancelOne () {
            this.videoStateTwo = false;
        },
        // 点击s删除收货地址
        del (IdOne) {
            var axios = require('axios');
            var config = {
                method: 'DELETE',
                url: this.$Schttp + '/vtp/app/customerDeliAddr/remove/' + IdOne,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    addrId: IdOne,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.SiteList()
                    }
                })
                .catch(function (error) {
                });

        },
        //设置默认地址
        setDefault (IdTwo, index) {
            var axios = require('axios');
            var config = {
                method: 'PUT',
                url: this.$Schttp + '/vtp/app/customerDeliAddr/updateDefualt/' + IdTwo,
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    addrId: IdTwo,
                    deliName: this.ruleForm.name,
                    address: this.ruleForm.DetailedAddress,
                    tel: this.ruleForm.tel,
                    provinceId: this.ShengId,
                    cityId: this.ShiId,
                    districtId: this.DistrictOne,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        for (var i = 0; i < this.AddressList.length; i++) {
                            this.AddressList[i].defaultTag = i == index;//i==index 表示i是否等于index,等于执行语句，不等不执行
                        }
                        this.AddressList.defaultTag(0, 0, ...this.AddressList.defaultTag(index, 1));//默认地址设在首位

                        this.SiteList()
                    }
                })
                .catch(function (error) {
                });
        },

        // 取消增加地址
        cancel () {

            this.videoStateOne = false;
        },
        // 点击新增确认提交地址
        affirm () {
            if (this.ruleForm.DetailedAddress == '') {
                this.$message({
                    message: '详情地址不可为空',
                    type: 'warning'
                });
                return
            }
            if (this.ruleForm.name == '') {

                this.$message({
                    message: '收货人不可为空',
                    type: 'warning'
                });
                return
            }
            if (this.ruleForm.tel == '') {
                this.$message({
                    message: '手机号不可为空',
                    type: 'warning'
                });
                return
            }
            let shop = /^1[3456789]\d{9}$/
            if (shop.test(this.ruleForm.tel)) {
                this.TiJiao()

            } else {
                this.$message({
                    message: '手机号格式不正确',
                    type: 'warning'
                });

            }
        },
        TiJiao () {
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/customerDeliAddr/add',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: {
                    deliName: this.ruleForm.name,
                    address: this.ruleForm.DetailedAddress,
                    tel: this.ruleForm.tel,
                    provinceId: this.ShengId,
                    cityId: this.ShiId,
                    districtId: this.DistrictOne,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.SiteList()
                        this.videoStateOne = false;
                    }
                    if (res.data.code == 500) {
                        this.$message({
                            message: '请选择省份，市级，县级',
                            type: 'warning'
                        });
                    }
                })
                .catch(function (error) {
                });
        },
    }
}
</script>

<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}

.colMax {
    width: 99%;
    height: 100%;
    display: flex;
    justify-content: center;

    .ColBox {
        width: 94%;
        height: 100%;

        .Law-Tab {
            width: 66%;
            margin-bottom: 2vh;
            .Law-Tab-Nav {
                font-size: 2vh;
                display: flex;

                .li {
                    margin-left: 2vh;
                    font-weight: bold;
                    color: rgba(221, 221, 221, 1);
                }
            }

            .actived {
                color: rgba(255, 255, 255, 1);
                border-bottom: 0.125rem solid #ffffff;
            }
        }

        .end-cont {
            display: none;
            width: 100%;
            height: 91.5%;

            .ColBoxOne {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .ColHead {
                    width: 25vh;
                    height: 25vh;
                    border-radius: 50%;
                }
            }

            .ColBoxTwo {
                width: 100%;
                height: 75%;
                margin-left: 2vh;
                .ColTitle {
                    width: 100%;
                    height: 8.5%;
                    display: flex;
                    justify-content: space-between;

                    .ColSite {
                        font-size: 2.2vh;
                        font-weight: 300;
                    }

                    .ColNewly {
                        font-size: 1.8vh;
                        color: rgba(100, 162, 255, 1);
                        margin-top: 0.37vh;
                        cursor: pointer;
                    }

                    .ColButton {
                        width: 11.5%;
                        height: 100%;
                        background-color: rgba(41, 98, 182, 1);
                        border-radius: 0.5vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .ColTable {
                    width: 100%;
                    height: 100%;
                    margin-top: 2vh;
                    border: 1px solid #64a2ff;

                    .ColTableOne {
                        width: 100%;
                        height: 7vh;
                        display: flex;
                        border: 1px solid #64a2ff;
                        .ColTitleTwo {
                            width: 16.6%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .ColContent {
                        width: 100%;
                        height: 9vh;
                        display: flex;
                        background-color: rgba(41, 98, 182, 0.3);
                        // border-bottom: 1px solid rgba(210, 210, 210, 1);

                        .ColContentOne {
                            width: 16.6%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .ColDefault {
                                width: 60%;
                                height: 60%;
                                border-radius: 0.5vh;
                                background-color: rgba(100, 162, 255, 1);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }

                            .ColDefaultone {
                                width: 65%;
                                height: 60%;
                                border-radius: 0.5vh;
                                background-color: rgba(182, 205, 239, 1);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .dis {
            display: block;
        }
    }

    .ColMaskOne {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 888;
        background-color: #000000;
        opacity: 0.6;
    }

    .ColMyPop {
        width: 65vw;
        height: 65vh;
        background-color: white;
        border-radius: 1vh;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 999;

        .ColFlex {
            width: 80%;
            height: 75%;

            .ColTitleFive {
                color: rgba(51, 51, 51, 1);
                font-size: 2.3vh;
            }

            .OrdSite {
                width: 100%;
                z-index: 9999;

                .OrdSiteOne {
                    width: 100%;
                    height: 80%;

                    .OrRedClass {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        .OrdDot {
                            width: 1vh;
                            height: 1vh;
                            border-radius: 50%;
                            background-color: rgba(100, 162, 255, 1);
                        }

                        .OrItem {
                        }
                    }
                }
            }

            .ColFlexOne {
                width: 100%;
                height: 12%;
                display: flex;
                justify-content: flex-end;
                margin-top: 3vh;

                .ColButtonOne {
                    width: 30%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-right: 2vh;
                }
            }
        }
    }
    /* 整个滚动条 */
    ::-webkit-scrollbar {
        width: 0.3vw;
        height: 0.1vh;
        background-color: #64a2ff;
    }

    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
        height: 0.3vh;
        background-color: #0f1755;
        border-radius: 32px;
    }
}
</style>
